import { Button } from "@mantine/core"
import Page from "../../molecules/page/page"
import { useNavigate } from "react-router-dom"

import SuccessFullPaymentIcon from "../../assets/successfullPayment.png";
import { useTranslation } from "react-i18next";

const SuccessfullDeletion = () => {
    const { t } = useTranslation(['common']);

    const navigate = useNavigate();
    return (
        <Page>
            <div style={{ textAlign: "center", marginTop: "10rem" }}>
                <img src={SuccessFullPaymentIcon} alt=""></img>
                <h2>{t('user.delete-successfull.title')}</h2>
                <p>{t('user.delete-successfull.description')}</p>
                <br />
                <Button color="dark" onClick={() => navigate("/")}>{t('user.delete-successfull.action-button')}</Button>
            </div>
        </Page>
    )
}

export default SuccessfullDeletion;