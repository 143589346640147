import { useContext, useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import IconButton from "../../molecules/IconButton/iconButton"
import Page from "../../molecules/page/page"
import Spinner from "../../molecules/Spinner/spinner"
import style from './experimental-new-scan.module.css'
import Modal from "../../molecules/modal/modal"
import CreateFolder from "../../molecules/createFolder/createFolder"
import Folder from "../../molecules/folder/folder"

import foldersIcon from '../../assets/folders.png'
import arrowRight from '../../assets/right-arrow.png'
import arrowLeft from '../../assets/left-arrow.png'
import brain from '../../assets/brain.png'
import factcheck from '../../assets/factcheck.png'
import feedback from '../../assets/feedback.png'
import { Accordion, Box, Button, CloseButton, Flex, Grid, Input, Loader, MediaQuery, Select, Text, Tooltip } from '@mantine/core';
import toast from 'react-hot-toast';

import { SimpleGrid } from "@mantine/core"
import { ImageCheckbox } from "../../molecules/imageCheckbox/imageCheckbox"
import { useTranslation } from "react-i18next"
import { handleFileUpload } from "./file-upload-utils"

import pdfIcon from '../../assets/pdf.png'
import docIcon from '../../assets/doc.png'
import textIcon from '../../assets/text.png'
import crossIcon from '../../assets/cross.svg'
import { useGetNumberOfScans } from "../../hooks/useGetNumberOfScans"
import { UserAndOrganzationContext } from "../../contexts/userAndOrganizationContext"
import useGetSubscription from "../../hooks/useGetSubscription"

const ExperimentalNewScan = ({ firestore, auth, firebase }) => {
    const { t } = useTranslation(['common']);

    const { getNumberOfScans } = useGetNumberOfScans();
    const { user, organization } = useContext(UserAndOrganzationContext);

    const [numberOfScans, setNumberOfScans] = useState(0);
    const { scanLimit, wordLimit, isProfessional, isTrial, subscriptionType } = useGetSubscription();

    const scansLeft = scanLimit - numberOfScans < 0 ? 0 : scanLimit - numberOfScans;

    useEffect(() => {
        getNumberOfScans(auth.currentUser.uid, user?.userData, isTrial).then((res) => setNumberOfScans(res));
    }, [])

    const folderIdUnchecked = window.location.href.split("/")[window.location.href.split("/").length - 1]
    const folderId = folderIdUnchecked === "new-scan" ? null : folderIdUnchecked;

    const navigate = useNavigate();
    const location = useLocation();

    const textTitleRef = useRef(null);

    const functions = firebase.app().functions("europe-west1");
    const contentScan = functions.httpsCallable("contentScan");

    //Global options and setup
    const [assessmentName, setAssessmentName] = useState("");

    const getCurrentLanguage = () => {
        const languagePreference = localStorage.getItem("languagePreference")
        if (languagePreference) return languagePreference;

        if (window.location.hostname === "app.ekte.ai") return "Norwegian";
        if (window.location.hostname === "app.verklig.ai") return "Swedish";
        if (window.location.hostname === "app.virkelig.ai") return "Danish";
        return "English"
    }

    const [language, setLanguage] = useState(getCurrentLanguage())

    const languageSelectionData = [
        { label: t("new-scan.language-selection.en"), value: "English" },
        { label: t("new-scan.language-selection.nb"), value: "Norwegian" },
        { label: t("new-scan.language-selection.se"), value: "Swedish" },
        { label: t("new-scan.language-selection.dk"), value: "Danish" },
    ]

    const checkFactsInitialValue = localStorage.getItem("checkFactsPreference") === "true" ? true : false ?? false;
    const [checkFacts, setCheckFacts] = useState(checkFactsInitialValue)
    const checkLanguageInitialValue = localStorage.getItem("checkLanguagePreference") === "true" ? true : false ?? false;
    const [checkLanguage, setCheckLanguage] = useState(checkLanguageInitialValue)

    const [newTechnicalTerm, setNewTechnicalTerm] = useState("");
    const [technicalTerms, setTechnicalTerms] = useState([]);

    const [openFolderSelection, setOpenFolerSelection] = useState(false)
    const [displayCreateFolder, setDisplayCreateFolder] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null)
    const [folders, setFolders] = useState([])

    const [loading, setLoading] = useState(false);
    const [hasLoadingFiles, setHasLoadingFiles] = useState(false)


    //Text specific states
    const [checkMultiple, setCheckMultiple] = useState(false);
    const [files, setFiles] = useState([]);

    const [showForm, setShowForm] = useState(true);

    const [name, setName] = useState("")
    const [text, setText] = useState("")
    const [textArray, setTextArray] = useState([]);


    //Functions
    const isDateMoreThan7DaysAgo = (inputDate) => {
        if (!inputDate) return false;

        const currentDate = new Date(); // Get the current date

        // Calculate the date 7 days ago from the current date
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);

        const inputDateObject = new Date(inputDate); // Convert the input date string to a Date object

        return inputDateObject < sevenDaysAgo; // Compare the input date with the date 7 days ago
    }

    const getFileIcon = (file) => {
        if (file?.file?.type === "application/pdf") return pdfIcon
        if (file?.file?.type === "application/msword" || file?.file?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") return docIcon

        return textIcon
    }

    const toggleViewForm = () => {
        if (showForm) {
            setShowForm(false)
            setText("")
            setName("")
        } else {
            setShowForm(true)
            // textTitleRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    const addTechnicalTerm = () => {
        const alreadyExists = technicalTerms.filter((technicalTerm) => technicalTerm === newTechnicalTerm).length > 0;

        if (!alreadyExists && newTechnicalTerm !== "") {
            const newTechnicalTerms = [...technicalTerms];
            newTechnicalTerms.push(newTechnicalTerm)
            setTechnicalTerms(newTechnicalTerms);
            setNewTechnicalTerm("");
        }
    }

    const removeTechnicalTerm = (technicalTermToBeRemoved) => {
        const copyOfTechnicalTerms = [...technicalTerms];
        const newTechnicalTerms = copyOfTechnicalTerms.filter((technicalTerm) => technicalTerm !== technicalTermToBeRemoved);
        setTechnicalTerms(newTechnicalTerms);
    }

    const addText = () => {
        const textArrayTemp = [...textArray];

        textArrayTemp.push({
            name: name === "" ? extractFirstWords(text, 80) : name,
            text: text
        });

        if (!checkMultiple) setCheckMultiple(true);
        setName("");
        setText("");

        setTextArray(textArrayTemp)
    }

    const removeText = (index) => {
        const textArrayCopy = [...textArray];
        textArrayCopy.splice(index, 1);
        setTextArray(textArrayCopy)
    }

    const removeFile = (index) => {
        const fileArrayCopy = [...files];
        fileArrayCopy.splice(index, 1);
        setFiles(fileArrayCopy)
    }

    const aggregateUserAnalytics = async () => {
        const functions = firebase.app().functions("europe-west1");
        const contentScan = functions.httpsCallable("aggregateUserAnalytics");

        console.log("analytics started")

        const result = await contentScan({ text: text });
        console.log("analytics finished")
    }

    const getFikenCompany = async () => {
        const functions = firebase.app().functions("europe-west1");
        const contentScan = functions.httpsCallable("getFikenCompany");

        const result = await contentScan();
        console.log(result)
        console.log(result.data)
    }

    const setPreferences = (languagePreference, checkFactsPreference, checkLanguagePreference) => {
        localStorage.setItem("languagePreference", languagePreference);
        localStorage.setItem("checkFactsPreference", checkFactsPreference)
        localStorage.setItem("checkLanguagePreference", checkLanguagePreference)
    }

    const scanOneText = async (e) => {
        e.preventDefault();
        if (loading) return;

        setLoading(true)

        try {
            const result = await contentScan({ text: text, name: name, parentId: folderId, provideFactcheck: checkFacts, provideLingualFeedback: checkLanguage, technicalTerms: technicalTerms, feedbackLanguage: language });
            const data = result.data;
            setLoading(false);

            setPreferences(language, checkFacts, checkLanguage)

            if (data.error === "Rate_Limit_Exceeded") console.log("Rate_Limit_Exceeded")
            if (!data.error) navigate(`/scan/${data.id}`);
        } catch (error) {
            console.log(error.code)

            setLoading(false);
            navigate('/unavailable');
        }
    }

    const handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    const countWords = (text) => {
        return text.split(" ").length;
    }

    const scanFiles = async (e) => {
        e.preventDefault();
        if (loading) return;

        const textArrayStored = [...textArray]

        if (text && text.split("").length > 199) {
            textArrayStored.push({
                name: name === "" ? extractFirstWords(text, 80) : name,
                text: text
            });
        }

        setLoading(true)

        const namesOfErredFiles = [];
        const apiPromises = [];

        files.forEach((file, index) => {
            const text = getNormalizedTextFromFile(file);

            if (countWords(text) < wordLimit && countWords(text) > 150) {
                const promise = scanText(getNormalizedTextFromFile(file), file.name).then((data) => {
                    if (data === false) {
                        namesOfErredFiles.push(file.name)
                    }
                }, (error) => {
                    namesOfErredFiles.push(file.name)
                })
                apiPromises.push(promise)
            }
        })

        textArrayStored.forEach((text, index) => {
            const promise = scanText(text.text, text.name).then((data) => {
                if (data === false) {
                    namesOfErredFiles.push(text.name)
                }
            }, (error) => {
                namesOfErredFiles.push(text.name)
            })
            apiPromises.push(promise)
        })

        await Promise.all(apiPromises)
        setLoading(false)
        if (namesOfErredFiles.length > 0) {
            toast.error(t("new-scan.toast.error"), {
                duration: 7000,
                position: 'bottom-center',
            })
        } else {
            toast.success(`${t("new-scan.toast.success")}. ${(files.length + textArray.length) > 1 ? t("new-scan.toast.files-added") : t("new-scan.toast.file-added")}`, {
                duration: 4000,
                position: 'bottom-center',
            })

            setPreferences(language, checkFacts, checkLanguage)
        }
        folderId ? navigate(`/scans/${folderId}`) : navigate("/scans");
    }

    const scanText = async (text, name) => {
        try {
            const result = await contentScan({ text: text, name: name, parentId: folderId, provideFactcheck: checkFacts, provideLingualFeedback: checkLanguage, technicalTerms: technicalTerms, feedbackLanguage: language });
            return true
        } catch (error) {
            return false
        }
    }

    const getNormalizedTextFromFile = (file) => {
        let text = "";
        if (file.type === "application/pdf") {
            file.text.text.forEach((textArrayItem) => {
                text += textArrayItem;
                text += "\n";
                text += "\n";
            })

            text = removeLineShiftsPDF(text);
        }
        else {
            text = removeLineShiftsDOCX(file.text.text)
        }

        return text;
    }

    function removeLineShiftsPDF(text) {
        // Remove line shifts that are not followed by an empty line
        const replaceLineBreakFollowedBySmallChar = text.replace(/(\r\n|\r|\n)([a-z])/g, ' $2');
        // Remove trailing line shifts
        // const withoutTrailingLineShifts = replaceSingleLineBreaks.replace(/\r?\n$/, '');

        return replaceLineBreakFollowedBySmallChar;
    }

    function removeLineShiftsDOCX(text) {
        // Remove multiple subsequent line shifts
        const withoutMultipleLineShifts = text.replace(/(\r\n|\r|\n){3,}/g, '\n\n');

        return withoutMultipleLineShifts;
    }

    useEffect(() => {

        const getFolders = async () => {
            const folders = []
            const foldersRef = firestore.collection("folders")
            currentFolder ?
                await foldersRef.where("uid", "==", auth.currentUser.uid).where("parentId", "==", folderId).orderBy('timestampCreated', 'desc').get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        folders.push({ id: doc.id, ...doc.data() })
                    })
                }).catch((error) => {
                    console.log(error)
                })
                :
                await foldersRef.where("uid", "==", auth.currentUser.uid).orderBy('timestampCreated', 'desc').get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        folders.push({ id: doc.id, ...doc.data() })
                    })
                }).catch((error) => {
                    console.log(error)
                })
            setFolders(folders)
        }

        const getCurrentFolder = async () => {
            try {
                const currentFolderRef = firestore.collection("folders").doc(folderId)
                const documentSnapshot = await currentFolderRef.get();
                if (documentSnapshot) {
                    setCurrentFolder({ id: documentSnapshot.id, ...documentSnapshot.data() })
                }
                else console.error("Error: Document not found")

            } catch (error) {
                console.error('Error fetching document data:', error);
            }
        }

        folderId && (currentFolder === null || (currentFolder && folderId !== currentFolder.id)) && getCurrentFolder(); //Avoids infinite fetches
        openFolderSelection && getFolders();

    }, [location, currentFolder, openFolderSelection, displayCreateFolder])

    useEffect(() => {
        files.length > 0 ? setCheckMultiple(true) : setCheckMultiple(false)

        let hasLoadingFiles = false;

        files.forEach((file) => {
            if (file.loading) hasLoadingFiles = true;
        })

        hasLoadingFiles ? setHasLoadingFiles(true) : setHasLoadingFiles(false)

    }, [files])

    const scanOptions = [
        { description: t('new-scan.options.ai-scan.label'), title: t('new-scan.options.ai-scan.title'), image: brain, checked: true },
        { description: t('new-scan.options.fact-check.label'), title: t('new-scan.options.fact-check.title'), image: factcheck, checked: checkFacts, onChange: () => { checkFacts ? setCheckFacts(false) : setCheckFacts(true) } },
        { description: t('new-scan.options.language-check.label'), title: t('new-scan.options.language-check.title'), image: feedback, checked: checkLanguage, onChange: () => { checkLanguage ? setCheckLanguage(false) : setCheckLanguage(true) } },
    ];

    const RemainingScansWarningBox = () => (
        <div>
            {(scansLeft) <= 0 && isTrial ?
                <div className={style.warningWrapper}>
                    {organization.organizationData ?
                        organization?.organizationData?.admins && organization?.organizationData?.admins.includes(auth.currentUser.uid) ?
                            <Flex columnGap={"0.25rem"} align={"center"}>
                                <p>{t('new-scan.contact-organization-admin')}
                                    {" "}
                                    <Link to="/organization" style={{ textDecoration: "underline" }}>{t('new-scan.contact-organization-link')}</Link>
                                    {" "}
                                    {organization.organizationData.subscription === "waiting" ?
                                        t("new-scan.contact-organization-suffix-admin-trial")
                                        :
                                        t("new-scan.contact-organization-suffix-admin")
                                    }
                                </p>
                            </Flex>
                            :
                            <Flex columnGap={"0.25rem"} align={"center"}>
                                <p>
                                    {t('new-scan.contact-organization')}
                                    {" "}
                                    <Link to="/organization" style={{ textDecoration: "underline" }}>{t('new-scan.contact-organization-link')}</Link>
                                    {" "}
                                    {t("new-scan.contact-organization-suffix")}
                                </p>
                            </Flex>
                        :
                        <Flex columnGap={"0.25rem"} align={"center"}>
                            <p>{`${scansLeft}/${scanLimit} ${t('new-scan.remaining-trial')}`}</p>
                            <Link to="/upgrade" style={{ textDecoration: "underline" }}>{t('new-scan.buy')}</Link>
                        </Flex>
                    }
                </div>
                :
                <p>{`${scansLeft}/${scanLimit} ${isTrial ? t('new-scan.remaining-trial') : t('new-scan.remaining-monthly')}`}</p>
            }
        </div>
    );

    return (
        <>
            <Modal
                isOpen={openFolderSelection}
                setIsOpen={setOpenFolerSelection}
            >
                {openFolderSelection && <div>
                    <h3>{t('folders.title')}</h3>

                    <div className={style.folderSelectionActionWrapper}>
                        <p>{folderId && currentFolder?.path ? `${t('folders.current-folder-label')}: ${currentFolder.path}` : `${t('folders.current-folder-label')}: ${t('folders.main-folder')}`}</p>
                        <div style={{ width: "fit-content" }}><IconButton primary text={t('folders.select-button')} onClick={() => setOpenFolerSelection(false)} /></div>
                    </div>
                    <br />
                    <div className={style.listHeader}>
                        <div>
                            <button className={folderId ? style.listHeaderIconButton : style.listHeaderIconButtonDisabled} onClick={() => currentFolder?.parentId ? navigate(`/new-scan/${currentFolder.parentId}`) : navigate(`/new-scan`)}><img src={arrowLeft} alt="Button back" className={style.arrowIcon}></img></button>
                            <button className={style.listHeaderIconButtonDisabled} ><img src={arrowRight} alt="Disabled button forward" className={style.arrowIcon}></img></button>
                        </div>
                        <button className={style.listHeaderButton} onClick={() => displayCreateFolder ? setDisplayCreateFolder(false) : setDisplayCreateFolder(true)}>{`+ ${t('folders.create-new-folder-button')}`}</button>
                    </div>

                    <CreateFolder auth={auth} firestore={firestore} folderId={folderId} currentFolder={currentFolder} display={displayCreateFolder} setDisplay={setDisplayCreateFolder} />

                    {folders && folders.length > 0 && folders.map((folder) => {
                        if ((!folderId && folder.parentId) || (folderId && folder.parentId !== folderId)) return null;
                        return (
                            <Folder folder={folder} pageName="new-scan" />
                        )
                    })}
                </div>}

            </Modal>
            <Page>
                <h1>{t('new-scan.title')}</h1>

                <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                    <div>
                        {(isTrial || scansLeft <= 5) && <RemainingScansWarningBox />}
                        <br />
                        <Text fz={"lg"}>{t('new-scan.settings')}</Text>
                        <br />

                        <Grid gutter={"1rem"}>
                            {/* <Grid.Col span={6}>
                                    <input type="text" className="input" placeholder={`${t('new-scan.title-placeholder')}...`} value={assessmentName} onChange={(e) => setAssessmentName(e.target.value)}></input>
                                </Grid.Col> */}
                            <Grid.Col span={12}>
                                <Flex justify={"space-between"} align={"self-end"} w={"100%"}>
                                    <Box w={"25rem"} h={"fit-content"}>
                                        <IconButton icon={foldersIcon} altText={""} text={extractLastWords(folderId && currentFolder?.path ? currentFolder.path : t('folders.main-folder'), 30)} outlinedPrimary onClick={(e) => { e.preventDefault(); setOpenFolerSelection(true) }} />
                                    </Box>
                                    <Select data={languageSelectionData} value={language} onChange={setLanguage} label={t("new-scan.language-selection.label")} />
                                </Flex>
                            </Grid.Col>
                        </Grid>
                        <br />

                        {(isProfessional || isTrial) && <>
                            <label>{t('new-scan.options.label')}</label><br />
                            <SimpleGrid
                                cols={3}
                                breakpoints={[
                                    { maxWidth: 'md', cols: 2 },
                                    { maxWidth: 'sm', cols: 1 },
                                ]}
                            >
                                {scanOptions.map((item) => <ImageCheckbox {...item} key={item.title} />)}
                            </SimpleGrid>
                            <br />

                            {/* <label>{t('new-scan.terms.label')}</label>
                            <Grid gutter={"0.25rem"}>
                                <Grid.Col span={4}>
                                    <Input
                                        placeholder={t('new-scan.terms.placeholder')}
                                        value={newTechnicalTerm}
                                        onChange={(e) => setNewTechnicalTerm(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault(); // Prevent the default action (form submission or whatever)
                                                addTechnicalTerm();
                                            }
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <Button className="plausible-event-name=addTechnicalTerm" onClick={() => addTechnicalTerm()} disabled={newTechnicalTerm.length < 2}>{t('new-scan.terms.add')}</Button>
                                </Grid.Col>
                            </Grid>
                            <Flex mt={"0.5rem"} wrap={"wrap"} maw={"800px"}>
                                {technicalTerms.map((technicalTerm) => (
                                    <div className={style.pin}>
                                        <Flex columnGap={"0.25rem"} align={"center"}>
                                            <Text fz={"sm"}>{technicalTerm}</Text>
                                            <CloseButton variant="transparent" onClick={() => removeTechnicalTerm(technicalTerm)} />
                                        </Flex>
                                    </div>
                                ))}
                            </Flex> */}
                        </>}



                        <div className={style.divider} />
                    </div>
                </MediaQuery>

                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                    <div>
                        {(scansLeft) <= 0 && isTrial && <RemainingScansWarningBox />}
                        <br />
                        <IconButton icon={foldersIcon} altText={""} text={extractLastWords(folderId && currentFolder?.path ? currentFolder.path : t('folders.main-folder'), 22)} outlinedPrimary onClick={(e) => { e.preventDefault(); setOpenFolerSelection(true) }} />
                        <br />
                        <Accordion chevronPosition="left">
                            <Accordion.Item value="evaluationOptions">
                                <Accordion.Control>{t('new-scan.options.label')}</Accordion.Control>
                                <Accordion.Panel>
                                    <SimpleGrid
                                        cols={3}
                                        breakpoints={[
                                            { maxWidth: 'md', cols: 2 },
                                            { maxWidth: 'sm', cols: 1 },
                                        ]}
                                    >
                                        {scanOptions.map((item) => <ImageCheckbox {...item} key={item.title} />)}
                                    </SimpleGrid>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </MediaQuery>

                <br />
                <Text fz={"lg"}>{t("new-scan.add-text-label")}</Text>
                <br />
                <div className={style.buttonGrid} >

                    <div className={style.addTextGrid}>
                        <div className={showForm ? style.addTextButtonActive : style.addTextButton} tabIndex={0} onClick={() => toggleViewForm()}>
                            {showForm ? `- ${t('new-scan.add-text')}` : `+ ${t('new-scan.add-text')}`}
                        </div>

                        <Tooltip label={t("new-scan.add-files-tooltip")} withArrow>
                            <div className={style.fileListLabelsRight}>
                                <label className={style.fileReselector} htmlFor="reFileSelector">
                                    <span>{t('new-scan.add-files')}</span>
                                </label>
                                {!hasLoadingFiles && <input
                                    style={{ display: "none" }}
                                    id="reFileSelector"
                                    type="file"
                                    accept=".pdf,.docx"
                                    multiple
                                    onChange={(e) => { handleFileUpload(e, files, setFiles, (scansLeft) < 35 ? (scansLeft) : 35); setShowForm(false) }}
                                />}
                            </div>
                        </Tooltip>
                    </div>
                </div>


                {(files.length + textArray.length) > 0 && <div className={style.fileList}>
                    {files.map((file, index) => (
                        <div className={style.fileItem}>
                            <img src={getFileIcon(file)} className={style.fileIcon} alt=""></img>
                            <span className={file.loading !== true && (countWords(getNormalizedTextFromFile(file)) > wordLimit || countWords(getNormalizedTextFromFile(file)) < 150) ? style.lineThrough : ""}>{file.name}</span>
                            <div className={style.fileItemRight}>
                                {/* <span className={style.sizeLabel}>{file.size > 999999 ? `${Math.round(file.size / 1000000)} MB` : `${Math.round(file.size / 1000)} KB`}</span> */}
                                <span
                                    className={file.loading !== true && (countWords(getNormalizedTextFromFile(file)) > wordLimit || countWords(getNormalizedTextFromFile(file)) < 150) ? style.sizeLabelError : style.sizeLabel}
                                >
                                    {file.loading ? "..." : `${countWords(getNormalizedTextFromFile(file))} ord`}
                                </span>

                                {file.loading ?
                                    <Loader size="sm" color="dark" variant="dots" />
                                    :
                                    <button className={style.optionIconButton} onClick={() => !hasLoadingFiles && removeFile(index)}>
                                        <img className={style.optionIcon} src={crossIcon} alt="options"></img>
                                    </button>
                                }
                            </div>
                        </div>
                    ))}
                    {textArray.map((text, index) => (
                        <div className={style.fileItem}>
                            <img src={getFileIcon()} className={style.fileIcon} alt=""></img>
                            <span>{text.name}</span>
                            <div className={style.fileItemRight}>
                                <span className={style.sizeLabel}>{`${text.text.split(" ").length} ${t('new-scan.words')}`}</span>
                                <button className={style.optionIconButton} onClick={() => !hasLoadingFiles && removeText(index)}>
                                    <img className={style.optionIcon} src={crossIcon} alt="options"></img>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>}


                {showForm &&
                    <>
                        <div className={style.divider} />

                        {(files.length + textArray.length) > 0 ? <h4 className={style.addNewTextTitle}>{t('new-scan.add-text')}</h4> : <div className={style.addNewTextTitle} />}
                        <div className={style.nameWrapper}>
                            <input type="text" className="input" placeholder={`${t('new-scan.title-placeholder')}...`} autoFocus={(files.length + textArray.length) > 0} value={name} onChange={(e) => setName(e.target.value)}></input>
                        </div>

                        <div className={style.topBar}>
                            <label>{`${countWords(text) - 1}/${wordLimit} ${t('new-scan.words')}`}</label>
                            <label>{countWords(text) - 1 < 150 ? `150 ${t('new-scan.minimum-words')}` : ""}</label>
                        </div>
                        <textarea className={style.textarea} placeholder={t('new-scan.text-placeholder')} value={text} onChange={(e) => { setText(e.target.value); handleKeyDown(e) }}></textarea>

                        <div className={style.actionGroup} ref={textTitleRef}>
                            <IconButton outlinedPrimary text={t('common.cancel')} onClick={(e) => toggleViewForm()} />
                            <IconButton primaryBlue text={(files.length + textArray.length) > 0 ? `+ ${t('new-scan.add-text')}` : `+ ${t('new-scan.add-more-texts')}`} onClick={(e) => addText()} disabled={countWords(text) < 151 || countWords(text) > (wordLimit + 1) || numberOfScans + textArray.length >= scanLimit} />
                            <label className={style.topBarRight}>{t('new-scan.languages')}</label>
                        </div>
                    </>
                }
                <br />
            </Page>

            <div className={style.submitGrid}>
                <div className={style.submitContent}>
                    <Flex gap={"1rem"}>
                        {!loading ?
                            (scansLeft <= 0 && subscriptionType === "trial") ?
                                <p style={{ fontWeight: "500" }}>{`${isTrial ? t("new-scan.trial-ended-bottom") : t("new-scan.limit-reached-bottom")} `}<Link to="/upgrade" style={{ textDecoration: "underline" }}>{t("new-scan.upgrade")}</Link></p>
                                :
                                <IconButton
                                    className={checkFacts || checkLanguage ? "plausible-event-name=ScanWithFeedback" : "plausible-event-name=Scan"}
                                    primary
                                    text={(files.length + textArray.length) > 1 ? `${t('new-scan.submit')} ${(files.length + textArray.length)} ${t('new-scan.texts')} →` : `${t('new-scan.submit')} ${t('new-scan.text')} →`}
                                    onClick={(e) => (files.length + textArray.length) > 0 ? scanFiles(e) : scanOneText(e)}
                                    disabled={((countWords(text) < 151 || countWords(text) > (wordLimit + 1)) && (files.length + textArray.length) === 0) || hasLoadingFiles || numberOfScans >= scanLimit || scansLeft <= 0}
                                />
                            :
                            <p style={{ fontWeight: "500" }}>{(files.length + textArray.length) > 1 ? `${t('new-scan.loading')} ${(files.length + textArray.length)} ${t('new-scan.texts')}` : `${t('new-scan.loading')} ${t('new-scan.text')}`} </p>
                        }
                        {loading ? <Spinner /> : null}
                    </Flex>
                </div>
            </div>
        </>
    )
}

export default ExperimentalNewScan;

const extractLastWords = (text, maxChars) => {
    const length = text.split("").length;
    if (length < maxChars) return text;

    let extractedText = text.substring(length - maxChars, length);
    extractedText = "..." + extractedText;
    return extractedText;
};

const extractFirstWords = (text, maxChars) => {
    var extractedText = text.substring(0, maxChars);
    var lastSpace = extractedText.lastIndexOf(" ");
    extractedText = extractedText.substring(0, lastSpace) + "...";
    return extractedText;
};
