import { useNavigate } from "react-router-dom";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";

import style from './delete-user.module.css'
import { useTranslation } from "react-i18next";
import { Button, Flex, Modal, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const DeleteUser = ({ auth }) => {
    const navigate = useNavigate();
    const { t } = useTranslation(['common']);
    const [opened, { open, close }] = useDisclosure(false);

    const deleteUser = () => {
        auth.currentUser.delete().then(() => {
            navigate("/deleted")
        }).catch((error) => {
            open();
        });
    }

    return (
        <Page>
            <div className={style.wrapper}>
                <h3>{t('user.delete-user-confirmation.title')}</h3>
                <p>{t('user.delete-user-confirmation.description')}</p>
                <br />
                <div className={style.options}>
                    <IconButton text={t('user.delete-user-confirmation.confirmation-button')} negative onClick={() => deleteUser()} />
                    <IconButton text={t('common.cancel')} outlined onClick={() => navigate(-1)} />
                </div>
            </div>

            <Modal opened={opened} onClose={close} centered title={<Title fz={18} fw={600}>{t('user.delete-user-confirmation.re-auth.title')}</Title>}>
                <Text>{t('user.delete-user-confirmation.re-auth.description')}</Text>

                <Flex mt={"2rem"} columnGap={"0.5rem"} justify={"right"}>
                    <Button color="dark" variant="outline" onClick={close}>{t("common.cancel")}</Button>
                    <Button color="dark" variant="filled" onClick={() => auth.signOut()}>
                        {t('user.delete-user-confirmation.re-auth.sign-in-again')}
                    </Button>
                </Flex>
            </Modal>
        </Page>
    )
}

export default DeleteUser;